export enum ExportType {
  FullSDWIS = "full_sdwis",
  MostRecentSDWIS = "most_recent_sdwis",
  PWSSubmissionPeriod = "pws_submission_period",
  StateUtilities = "state_utilities",
  SubmissionDetails = 'submission_details',
  SubmissionQuestionsAZ = 'submission_questions_az',
  SubmissionQuestionsDE = 'submission_questions_de',
  SubmissionQuestionsGA = 'submission_questions_ga',
  SubmissionQuestionsIN = 'submission_questions_in',
  SubmissionQuestionsOR = 'submission_questions_or',
  SubmissionQuestionsRI = 'submission_questions_ri',
}
