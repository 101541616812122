import React from "react";
import ExportDropdown from "src/modules/Exports/ExportDropdown";
import { SubmissionDetailsExportParams, useCreateSubmissionDetailsExportMutation } from "src/services/export-service-api";
import { ExportType } from "src/types/enums/ExportRequest";
import { displayDownloadStarted, toastError } from "src/utils/toast";

const Exporter: React.FC<SubmissionDetailsExportParams> = ({
  submissionId,
  params,
}) => {
  const [exportSubmissionDetails] = useCreateSubmissionDetailsExportMutation();
  const options = [
    {
      key: "details",
      text: "Submission Details",
      value: ExportType.SubmissionDetails,
    },
  ];
  const handleExport = (): void => {
    exportSubmissionDetails({ submissionId, params }).catch(() =>
      toastError("Unable to generate export.")
    );
    displayDownloadStarted({ type: "export" });
  };

  return (
    <div style={{ marginLeft: 10, paddingTop: 3, paddingBottom: 3 }}>
        <ExportDropdown
        exportOptions={options}
        handleExport={handleExport}
        />
    </div>
  );
};

export default Exporter;
